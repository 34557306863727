<template>
  <div class="main-view">
    <div class="tableType">
      <el-radio-group v-model="table.params.type" @change="handleTabs">
        <el-radio-button :label="1">合作中</el-radio-button>
        <el-radio-button :label="2">合作终止</el-radio-button>
      </el-radio-group>
    </div>
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="用工单位" prop="name">
          <el-input clearable v-model="table.params.name" placeholder="请输入关键字"></el-input>
        </el-form-item>
        <el-form-item label="开票单位" prop="invoice_company_id">
          <el-select v-model="table.params.invoice_company_id" placeholder="请选择" clearable filterable>
            <el-option
              v-for="item in invoiceCompanyOpt"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询
          </el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <el-button :size="subUnitSize" type="primary" icon="el-icon-plus" @click="showEdit">创建</el-button>
        <template v-if="icId != 0">
          <el-button :size="subUnitSize" type="primary" plain @click="handleDownload">下载样表</el-button>
          <el-button :size="subUnitSize" type="primary" plain icon="el-icon-upload" @click="handleUpload">批量导入</el-button>
        </template>
        <el-button :size="subUnitSize" type="warning" plain icon="el-icon-download" @click="handleExport">全部导出</el-button>
        <el-button :size="subUnitSize" type="warning" plain icon="el-icon-download" @click="handleExportBatch">批量导出</el-button>
      </div>
    </div>
    
    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      :showSelect="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      @handleSelectionChange="handleSelectionChange"
    >
      <template v-slot:manager_fee_type="{row}">
        <span>{{managerFeeType[row.manager_fee_type]}}</span>
      </template>
      <template v-slot:settlement_type="{row}">
        <span>{{settlementType[row.settlement_type]}}</span>
      </template>
      <template v-slot:action="{row}">
        <el-button type="text" @click='showEdit(row)'>修改</el-button>
        <el-button type="text" @click="showDetail(row)">查看</el-button>
        <!-- <el-button type="text" @click='showUpload(row)'>上传合同</el-button> -->
        <!-- <el-button type="text" @click='remove(row)'>删除</el-button> -->
      </template>
    </VTable>

    <!-- 批量导入 -->
    <el-dialog
      title="导入"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <div style="display: flex;align-items: center;justify-content: center;">
        <el-upload
          class="upload-demo"
          drag
          :limit="1"
          action=""
          :headers="{token:token}"
          :file-list="fileList"
          :on-remove="removeFile"
          :multiple="false"
          :http-request="httpRequestBack"
          accept=".xls,.xlsx">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="uploadContract">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 导入成功弹窗 -->
    <el-dialog
      title="导入成功"
      :visible.sync="successDialogVisible"
      width="30%"
      center>
      <p style="text-align: center;">已导入{{templateDialog.result}}条</p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="successClose">关闭</el-button>
      </span>
    </el-dialog>
    <!-- 导入异常弹窗 -->
    <el-dialog
      title="导入异常"
      :visible.sync="errorDialogVisible"
      width="30%"
      center>
      <div style="text-align:center;">
        <el-button type="text" @click="innerVisible = true">查看异常<i class="el-icon-warning el-icon--right"></i></el-button>
      </div>
      <el-dialog
        width="30%"
        title="异常详情"
        :visible.sync="innerVisible"
        append-to-body
        center>
        <div v-if="templateDialog.errors.length > 0" class="errorBox">
          <p v-for="(item, index) in templateDialog.errors" :key="index">{{index +1}}、{{item}}</p>
        </div>
        <div v-else>
          无错误信息
        </div>
      </el-dialog>
      <span slot="footer" class="dialog-footer">
        <el-button @click="errorDialogVisible = false">取消上传</el-button>
        <el-button type="primary" @click="uploadSubmit2">继续上传</el-button>
      </span>
    </el-dialog>

    <!-- 上传合同 -->
    <el-dialog
      title="上传合同"
      :visible.sync="dialogUploadVisible"
      width="30%"
      :before-close="handleClose2">
      <div>
        <el-upload
          class="upload-demo"
          drag
          :limit="1"
          action=""
          :headers="{token:token}"
          :file-list="fileList"
          :on-remove="removeFile"
          :multiple="false"
          :http-request="httpRequestBack"
          accept=".pdf">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将PDF格式文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
        <div @click="openPdf">已上传的合同</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="uploadSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <edit ref="edit" @refresh="getTable" :invoiceCompanyOpt="invoiceCompanyOpt"></edit>
    <detail ref="detail"></detail>
    
  </div>
</template>

<script>
import Edit from './components/Edit.vue'
import Detail from './components/Detail.vue'
import VTable from '@/components/VTable';
import {exportData} from '@/util'
import {getStorage} from '@/storage'
import { mapGetters } from 'vuex'
import { mixinTable } from '@/mixins/table.js'
export default {
  name: '',
  mixins:[mixinTable],
  components: {
    Edit,
    Detail,
    VTable
  },
  computed: {
    ...mapGetters({
      invoiceCompanyOpt: 'invoiceCompany'
    })
  },
  data() {
    return {
      icId: Number(getStorage('icId')), 
      token: getStorage('token'),
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      field: [
        { name: "id", label: "ID", width:"60"},
        { name: "name", label: "用工单位", width:"160", hidden: false },
        { name: "salary", label: "基本工资/元", hidden: false },
        { name: "manager_fee", label: "管理费金额/元", hidden: false},
        { name: "manager_fee_type", label: "承担人员", hidden: false},
        { name: "full_freq_days", label: "满勤天数/天", hidden: false},
        { name: "settlement_day", label: "发薪日", hidden: false},
        { name: "settlement_type", label: "薪资结算", hidden: false},
        { name: "invoice_company_name", label: "开票单位", width:"240", hidden: false},
        { name: "contract_end_day", label: "合同截止日", width:"160", hidden: false},
        { name: "created_at", label: "创建时间", width:"160", hidden: false},
        { name: "action", label: "操作", width: "120", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          ids: [],
          type: 1,
          name: '',
          invoice_company_id: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      managerFeeType: {
        1:'企业',
        2:'员工'
      },
      settlementType: {
        1:'当月薪资',
        2:'上月薪资'
      },
      rowId: '',
      isDetail: false,

      dialogVisible: false,
      fileList: [],
      upload: {
        company_id: '',
        file: '',
      },
      curfile: '',
      // 导入后提示
      successDialogVisible: false, // 导入成功弹窗
      errorDialogVisible: false,  // 导入异常弹窗
      templateDialog: {
        skip_errors: '',
        result: '',
        errors: [],
      },
      innerVisible: false,
      dialogUploadVisible: false,
      
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.$http.post('/admin/company/list', this.table.params).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 切换状态
    handleTabs() {
      this.table.params.page = 1;
      this.table.data = [];
      this.getTable();
    },
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      if (!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    // 删除
    remove(row) {
      this.$confirm("是否删除此条记录？", "删除", {
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            console.log('删除接口');
            done()
          } else {
            done()
          }
        }
      })
    },
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 批量选择
    handleSelectionChange(batchData) {
      this.table.params.ids = [];
      if(batchData.length > 0) {
        batchData.forEach(v => {
          this.table.params.ids.push(v.id)
        })
      }
    },
    // 全部导出
    handleExport() {
      if(this.table.data.length > 0) {
        let _params = {
          type:this.table.params.type,
          name: this.table.params.name,
          invoice_company_id: this.table.params.invoice_company_id,
        }
        exportData(_params,'/admin/company/export')
      } else {
        this.$message.warning('没有需要导出的数据！')
      }
    },
    // 批量导出
    handleExportBatch() {
      if(this.table.params.ids.length > 0) {
        this.table.params.ids = this.table.params.ids.toString();
        exportData(this.table.params,'/admin/company/export')
      } else {
        this.$message.warning("请选择要批量导出的数据！");
      }
    },
    // 下载样表
    handleDownload() {
      exportData({},'/admin/company/downloadSample')
    },
    // 批量导入
    handleUpload() {
      this.fileList = [];
      this.templateDialog.errors = [];
      this.dialogVisible = true;
    },
    httpRequestBack(file) {
      this.curfile = file.file
    },
    // 导入
    uploadSubmit() {
      if(this.curfile) {
        this.templateDialog.skip_errors = 0; // 默认上传
        this.uploadRequer();
      } else {
        this.$message.warning('请选择要导入的文件')
      }
    },
    // 继续导入
    uploadSubmit2() {
      this.templateDialog.skip_errors = 1; // 继续上传
       this.uploadRequer();
    },
    // 导入请求
    uploadRequer() {
      let formData = new FormData();
      formData.append('file', this.curfile);
      formData.append('skip_errors', this.templateDialog.skip_errors)
      this.$http.post('/admin/company/import', {formData, type:'upload', obj:{skip_errors: this.templateDialog.skip_errors}}).then(res => {
        if(res.code === 1) {
          this.successDialogVisible = true;
          this.templateDialog.result = res.data.result;
        } else {
          this.errorDialogVisible = true;
          this.templateDialog.errors = res.data.errors;
        }
      }).finally(() => {
        this.dialogVisible = false
      })
    },
    handleClose() {
      this.dialogVisible = false
    },
    handleClose2() {
      this.dialogUploadVisible = false
    },
    removeFile() {
      this.fileList = [];
    },
    // 关闭成功的弹窗
    successClose() {
      this.successDialogVisible = false;
      this.errorDialogVisible = false;
      this.getTable();
    },
    // 上传合同
    showUpload(row) {
      this.dialogUploadVisible = true;
    },
    uploadContract() {
      let formData = new FormData();
      formData.append('file', this.curFile);
      this.$http.post('', {formData, type:'upload', obj:{}}).then(res => {
        if(res.code === 1) {
          this.dialogUploadVisible = false;
        }
      })
    },
    openPdf() {
      window.open('')
    }
  }
}
</script>
<style scoped>
.uploadTitle {
  color: #db4242;
  font-weight: bold;
  text-align: center;
}
.uploadContent {
  min-height: 300px;
}
.errorBox {
  max-height: 450px;
  overflow-y: scroll;
}
</style>