<template>
  <el-dialog
    append-to-body
    width="900px"
    :title="`${isChange ? '修改' : '新增'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref='elFormDom'
      label-width='100px'
      label-position='right'
      :model='form.data'
      :rules='form.rules'
      :size="subUnitSize"
    >
      <el-row >
        <el-col :span="12">
          <el-form-item prop='name' label='用工单位'>
            <el-input
              v-model.trim='form.data.name'
              clearable
              placeholder='请输入用工单位'
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop='simple_name' label='企业简称'>
            <el-input
              v-model.trim='form.data.simple_name'
              clearable
              maxlength="10"
              placeholder='请输入企业企业简称'
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop='credit_code' label='社会信用代码'>
            <el-input
              v-model.trim='form.data.credit_code'
              clearable
              placeholder='请输入社会信用代码'
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop='tax_point' label='税率'>
            <el-input
              v-model.number='form.data.tax_point'
              placeholder='请输入税率'
            >
              <span slot="suffix">%</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop='invoice_company_id' label='开票公司'>
            <el-select v-model="form.data.invoice_company_id" placeholder="请选择" clearable filterable class="width100">
              <el-option
                v-for="item in invoiceCompanyOpt"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop='manager_fee' label='管理费金额'>
            <el-input
              v-model.trim='form.data.manager_fee'
              placeholder='请输入管理费金额'
            >
              <span slot="suffix">元</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop='manager_fee_type' label='承担人员'>
            <el-select v-model="form.data.manager_fee_type" placeholder="请选择" class="width100">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop='salary' label='基本工资'>
            <el-input
              v-model.trim='form.data.salary'
              placeholder='请输入基本工资'
            >
              <span slot="suffix">元</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop='settlement_type' label='薪资结算'>
            <el-select v-model="form.data.settlement_type" placeholder="请选择" class="width100">
              <el-option
                v-for="item in settlementType"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop='settlement_day' label='发薪日' >
            <el-input-number v-model="form.data.settlement_day"
              :min="1"
              :max="31"
              placeholder=""
              controls-position="right"
              class="width100"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop='full_freq_days' label='满勤天数'>
            <el-input-number v-model="form.data.full_freq_days"
              :min="1"
              :max="31"
              placeholder=""
              controls-position="right"
              class="width100"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="is_limit_bank" label="开户行限制">
            <el-radio-group v-model="form.data.is_limit_bank" @change="handleIsBank">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="limit_bank_id" label="开户行">
            <el-select v-model="form.data.limit_bank_id" multiple :disabled="form.data.is_limit_bank===0 ? true: false" placeholder="请选择" @focus="getBank" class="width100">
              <el-option
                v-for="item in bankType"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop='contract_end_day' label='合同截止日'>
            <el-date-picker
              v-model="form.data.contract_end_day"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              class="width100">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="exempt_last_manage_fee" label="离职月收取管理费">
            <el-radio-group v-model="form.data.exempt_last_manage_fee">
              <el-radio :label="0">收取</el-radio>
              <el-radio :label="1">免除</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="jobsBg">
        <el-row  v-for="(item,index) in postesArr" :key="index">
          <el-col :span="9">
            <el-form-item prop='jobs_name' label='岗位名称'>
              <el-input
                v-model.trim='item.name'
                placeholder='请输入'
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop='trial_salary' label='试用期工资'>
              <el-input
                v-model.trim='item.trial_salary'
                placeholder='请输入'
                @input="item.trial_salary=item.trial_salary.replace(/[^\d]/g,'')"
              >
              <span slot="suffix">元</span>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop='regular_salary' label='转正工资'>
              <el-input
                v-model.trim='item.regular_salary'
                placeholder='请输入'
                @input="item.regular_salary=item.regular_salary.replace(/[^\d]/g,'')"
              >
                <span slot="suffix">元</span>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2" style="margin-left:10px;">
            <el-button type="primary" icon="el-icon-plus" size="mini" circle @click="plusBtn"></el-button>
            <el-button type="danger" icon="el-icon-minus" size="mini" circle @click="minusBtn(index)"></el-button>
          </el-col>
        </el-row>
      </div>
      <el-row>
        <el-col :span="12">
          <el-form-item prop='address' label='企业地址'>
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 6}"
              placeholder="请输入"
              v-model="form.data.address"
              maxlength="200"
              show-word-limit>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop='mark' label='备注'>
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 6}"
              placeholder="请输入备注"
              v-model="form.data.mark"
              maxlength="200"
              show-word-limit>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row> 
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  import { REG_EXP } from '@/util'
  export default {
    name: 'CompanyEdit',
    props:['invoiceCompanyOpt'],
    data() {
      var checkRate = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('请输入税率'));
        }
        if (!Number.isInteger(value)) {
          return callback(new Error('税率只能输入数字'));
        } else {
          callback();
        }
      };
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            name: '',
            credit_code: '',
            tax_point: 5,
            invoice_company_id: '',
            manager_fee: '',
            manager_fee_type: '',
            salary: '',
            settlement_type: '',
            // settlement_day: '',
            // full_freq_days: '',
            is_limit_bank: 1,
            limit_bank_id: '',
            contract_end_day: '',
            mark: '',
            simple_name: '', //  企业简称
            exempt_last_manage_fee: 0,  // 管理费（ 0-不免除；1-免除）
            address: '',
            postes: [],
          },
          rules: {
            name: [{ required: true, message:'请输入用工单位', trigger: 'blur'}],
            tax_point: [{ validator:checkRate, required: true, trigger: 'blur'}],
            manager_fee: [
              { required: true, message:'请输入管理费金额', trigger: 'change'},
              {
                pattern: REG_EXP.money,
                message: "请填写正确的金额格式，如：188.88",
                trigger: "change",
              }
            ],
            invoice_company_id: [{ required: true, message:'请选择开票公司', trigger: 'change'}],
            manager_fee_type: [{ required: true, message:'请选择承担人员', trigger: 'change'}],
            salary: [
              { required: true, message:'请输入基本工资', trigger: 'change'},
              {
                pattern: REG_EXP.money,
                message: "请填写正确的金额格式，如：188.88",
                trigger: "change",
              }
            ],
            settlement_type: [{ required: true, message:'请选择薪资结算', trigger: 'change'}],
            settlement_day: [{ required: true, message:'请输入1-31的数字', trigger: 'change'}],
            full_freq_days: [{ required: true, message:'请输入满勤天数', trigger: 'change'}],
            contract_end_day: [{ required: true, message:'请选择合同截止日', trigger: 'change'}],
            limit_bank_id: [{ required: true, message:'请选择开户行', trigger: 'change' }],
            simple_name: [{ required: true, message:'请输入企业简称', trigger: 'change' }],
           
          }
        },
        optOrgType: [],
        optOrg: [],
        options: [{
          value: 1,
          label: '企业'
        },
        {
          value: 2,
          label: '员工'
        }],
        settlementType: [
          {
            value: 1,
            label: '当月薪资'
          },
          {
            value: 2,
            label: '上月薪资'
          }
        ],
        bankType: [], // 开户行
        postesArr: [
          {
            name: '',
            trial_salary: '',
            regular_salary: '',
          }
        ]
      }
    },
    methods: {
      // 获取企业详情
      async getDetail(row) {
        this.isChange = true
        await this.getBank()
        await this.$http.post('/admin/company/detail', { id: row.id }).then(res => {
          if(res.code === 1) {
            common.deepClone(this.form.data, res.data)
            if(res.data.postes.length > 0) {
              this.postesArr = res.data.postes;
            }
            this.form.data.limit_bank_id = res.data.limit_bank_id == 0 ? '' : res.data.limit_bank_id.split(',').map(Number)
            console.log(this.form.data.limit_bank_id)
            console.log(2222222)
            if(this.form.data.is_limit_bank ===1) {
              this.form.rules.limit_bank_id = [{required: true, message:'请选择开户行', trigger: 'change'}]
            } else {
              this.form.rules.limit_bank_id = [{}]
            }
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      // 获取银行卡
      getBank() {
        this.$http.get('/admin/config/banks', {params:{}}).then(res => {
          if(res.code ===1) {
            this.bankType = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        this.postesArr =  [
          {
            name: '',
            trial_salary: '',
            regular_salary: '',
          }
        ]
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        console.log(this.form.data.limit_bank_id)
        // return;
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            if(this.postesArr[0].name == '') {
              this.$message.warning('请输入岗位信息')
              return
            }
            let apiUrl = !this.isChange ? '/admin/company/create' : '/admin/company/edit'
            this.form.data.postes = JSON.stringify(this.postesArr)
            if(this.form.data.limit_bank_id.length > 0) {
              this.form.data.limit_bank_id = this.form.data.limit_bank_id.join(",")
            }
            console.log(this.form.data)
            this.form.loading = true;
            this.$http.post(apiUrl, this.form.data).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
                this.$message.success('操作成功！')
              } else {
                this.$message.error(res.msg)
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      // 开户行限制
      handleIsBank(val) {
        if(val === 1) {
          this.form.rules.limit_bank_id = [
            { required: true, message:'请选择开户行', trigger: 'change' }
          ]
        } else {
          this.form.data.limit_bank_id = ''
          this.form.rules.limit_bank_id = [{ }]
        }
      },
      // 加
      plusBtn() {
        let str = {
          name: '',
          trial_salary: '',
          regular_salary: '',
        }
        this.postesArr.push(str)
      },
      // 减
      minusBtn(index) {
        if(this.postesArr.length > 1) {
          this.postesArr.splice(index,1)
        }
      },
    }
  }
</script>
<style scoped>
.width100 {
  width: 100%;
}
.jobsBg {
  background: #f9f9f9;
  padding-top: 20px;
  margin-bottom: 20px;
  border: solid 1px #eee;
}
</style>
