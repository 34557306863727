<template>
  <el-dialog
    append-to-body
    width="800px"
    title="查看"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div class="tableWrap">
      <table cellspacing="0" cellpadding="0" border="0" class="el-table__body">
        <tbody>
          <tr>
            <td class="des" style="width:120px;">用工单位</td>
            <td>{{ detailInfo.name }}</td>
            <td colspan="4" class="des" style="width:120px;">社会信用代码</td>
            <td>{{ detailInfo.credit_code }}</td>
          </tr>
          <tr>
            <td class="des" style="width:120px;">税    率</td>
            <td>{{ detailInfo.tax_point }}%</td>
            <td colspan="4" class="des" style="width:120px;">开票公司</td>
            <td>{{ detailInfo.invoice_company_name }}</td>
          </tr>
          <tr>
            <td class="des" style="width:120px;">管理费金额</td>
            <td>{{ detailInfo.manager_fee}}</td>
            <td colspan="4" class="des" style="width:120px;">承担人员</td>
            <td>{{ managerFeeType[detailInfo.manager_fee_type] }}</td>
          </tr>
          <tr>
            <td class="des" style="width:120px;">基本工资</td>
            <td>{{ detailInfo.salary }}</td>
            <td colspan="4" class="des" style="width:120px;">发 薪 日</td>
            <td>{{ detailInfo.settlement_day }}号</td>
          </tr>
          <tr>
            <td class="des" style="width:120px;">薪资结算</td>
            <td>{{ settlementType[detailInfo.settlement_type] }}</td>
            <td colspan="4" class="des" style="width:120px;">满勤天数</td>
            <td>{{ detailInfo.full_freq_days }}天</td>
          </tr>
          <tr>
            <td class="des" style="width:120px;">合同截止日</td>
            <td>{{ detailInfo.contract_end_day }}</td>
            <td colspan="4" class="des" style="width:120px;">备注</td>
            <td>{{ detailInfo.mark }}</td>
          </tr>
          <tr>
            <td class="des" style="width:120px;">企业简称</td>
            <td>{{ detailInfo.simple_name }}</td>
            <td colspan="4" class="des" style="width:120px;">管理费</td>
            <td>{{ detailInfo.exempt_last_manage_fee == 0 ? '收取' : '免除' }}</td>
          </tr>
          <tr>
            <td class="des" style="width:120px;">企业地址</td>
            <td colspan="6">{{ detailInfo.address }}</td>
          </tr>
        </tbody>
      </table>
      <p style="margin-top:10px;"></p>
      <el-descriptions class="margin-top" title="" :column="3"  border v-for="item in detailInfo.postes" :key="item.id">
        <el-descriptions-item :labelStyle="{width:'120px'}" :contentStyle="{width:'220px'}" label="岗位名称">{{item.name}}</el-descriptions-item>
        <el-descriptions-item :labelStyle="{width:'120px'}" :contentStyle="{width:'220px'}" label="试用期工资">{{item.trial_salary}}</el-descriptions-item>
        <el-descriptions-item :labelStyle="{width:'120px'}" :contentStyle="{width:'220px'}" label="转正工资">{{item.regular_salary}}</el-descriptions-item>
      </el-descriptions>
    </diV> 
  </el-dialog>
</template>
<script>
  export default {
    name: 'PerformanceDrawDetail',
    data() {
      return {
        visible: false,
        detailInfo: {
          postes:[],
        },
        managerFeeType: {
          1:'企业',
          2:'员工'
        },
        settlementType: {
          1:'当月薪资',
          2:'上月薪资'
        }
      }
    },
    created() {
      this.getDetail();
    },
    methods: {
      getDetail(row) {
        console.log(row);
        if(!!row) {
          this.$http.post('/admin/company/detail', { id: row.id }).then(res => {
            if(res.code === 1) {
              this.detailInfo = res.data
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      },
      reset(done) {
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
     
    }
  }
</script>
<style scoped lang="scss">
// 详情自定义table表格样式
.tableWrap .list {
  padding: 0 12px;
  margin-bottom: 30px;
}
 .tableWrap table {
  width: 100%;
  border-collapse: collapse;
}
 .tableWrap table td{
  height: 30px;
  line-height: 22px;
  padding: 5px;
  border: 1px solid #ddd;
}
.tableWrap .des {
  background: #fbfbfb;
}
.tableWrap .d-title {
  height: 80px;
  line-height: 80px;
}

</style>
